import React from "react"

import styles from "./Topics.module.scss"

const Topic = ({ topic }) => {
  return (
    <div className={styles.item}>
      <div className={styles.content}>
        <h2>{topic.title}</h2>
        <p>{topic.description}</p>
      </div>
    </div>
  )
}

export default Topic
