import React, { useCallback, useState } from "react"
import cx from "classnames"
import emailjs from "emailjs-com"
import {gtagWrapper} from "../../../utils/gtagHelper";

import styles from "./ContactUsForm.module.scss"

const ContactUsForm = ({ className }) => {
  const [sended, setSended] = useState(false)

  const sendEmail = useCallback(e => {
    e.preventDefault()

    gtagWrapper('event', 'send-form', { 'event_category': 'form', });

    emailjs
      .sendForm(
        "service_31hngip",
        "template_reay589",
        e.target,
        "user_GN5cyuG405EPt3CPOlsOk"
      )
      .then(
        result => {
          if (result.status === 200) {
            setSended(true)
          } else {
            alert("Sorry your request don't send by technical issue.")
            console.log(result.text)
          }
        },
        error => {
          alert("Sorry your request don't send by technical issue.")
          console.log(error.text)
        }
      )
  }, [])

  return (
    <>
      {!sended && (
        <form className={cx(styles.container, className)} onSubmit={sendEmail}>
          <div className={styles.header}>
            <h2 className={styles.title}>Get in Touch with Aramaze</h2>
          </div>
          <div className={styles.contFormCol2}>
            <input
              name="name"
              placeholder="Name*"
              autoComplete="none"
              required
            />
            <input
              name="company"
              placeholder="Company*"
              autoComplete="none"
              required
            />
          </div>
          <input name="email" placeholder="Email*" required />
          <input name="phone" placeholder="Phone" />
          <textarea name="question" placeholder="Comment/Question" rows="4" />
          <div className={styles.contFormButton}>
            <input type="submit" value="Send Message" />
          </div>
        </form>
      )}
      {sended && (
        <div className={cx(styles.container, className)}>
          <h3>Thank you for your interest in our services!</h3>
          <p>We have received your message and will respond to you shortly.</p>
        </div>
      )}
    </>
  )
}

export default ContactUsForm
