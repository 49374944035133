import React from "react"
import { graphql } from "gatsby"

import AboutAs from "../components/AboutAsPage"

const AboutUsPage = ({ data }) => {
  const aboutUs = data.allStrapiAboutUs.nodes[0]
  return <AboutAs aboutUs={aboutUs} />
}

export const pageQuery = graphql`
  query AboutUsQuery {
    allStrapiAboutUs {
      nodes {
        id
        text
        leadershipDescription
        leaderships {
          id
          fullName
          role
          description
          photo {
            childImageSharp {
              fluid(maxWidth: 480) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        shortTopics {
          id
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 960) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

export default AboutUsPage
