import React from "react"
import cx from "classnames"

import styles from "./Leadership.module.scss"

const Leader = ({ leader }) => {
  const { fullName, role, description, photo } = leader

  return (
    <div className={styles.col}>
      <div className={styles.container}>
        <div
          className={styles.front}
          style={{ backgroundImage: `url(${photo.childImageSharp.fluid.src})` }}
        >
          <div className={cx(styles.member, styles.memberInfo)}>
            <h3>{fullName}</h3>
            <span>{role}</span>
          </div>
        </div>
        <div className={styles.back}>
          <div className={styles.inner}>
            <p>{description}</p>
          </div>
          <div className={`${styles.member} ${styles.memberInfo}`}>
            <h3>{fullName}</h3>
            <span>{role}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Leader
