import React from "react"
import ReactMarkdown from "react-markdown"

import Leader from "./Leader"
import styles from "./Leadership.module.scss"

const Leadership = ({ leaderships, description }) => (
  <section className={styles.leader}>
    <h3>Our Team</h3>
    {description && (
      <ReactMarkdown source={description} className={styles.content} />
    )}
    <div className={styles.leadership}>
      <h3>Leadership</h3>
      <div className={styles.cols}>
        {leaderships.map(leader => (
          <Leader leader={leader} key={leader.id} />
        ))}
      </div>
    </div>
  </section>
)

export default Leadership
