import React from "react"
import ReactMarkdown from "react-markdown"
import cx from "classnames"

import Layout, { Body } from "../Shared/Layout"
import Icon from "../Shared/Icon"
import SEO from "../Shared/Seo"
import ContactUsForm from "../Shared/ContactUsForm"
import transformImageUri from "../../utils/transformImageUri"
import Leadership from "./Leadership"
import Topics from "./Topics"
import {gtagWrapper} from "../../utils/gtagHelper";

import styles from "./AboutAsPage.module.scss"

const AboutAsPage = ({ aboutUs }) => {
  const { text, leadershipDescription, leaderships, shortTopics } = aboutUs

  return (
    <Layout>
      <SEO
        title="Professional Custom Software Development Services"
        description="Our multi-disciplinary teams combine business expertise with design thinking, engineering, modern operations practices and next-gen tools to optimize performance."
      />
      <Body>
        <section className={cx(styles.weAre, styles.text)}>
          <div className={styles.weAreRight}>
            <ReactMarkdown
              source={text}
              className={styles.content}
              transformImageUri={transformImageUri}
            />
          </div>
        </section>
        <section className={styles.text}>
          <Topics topics={shortTopics} />
        </section>
        <Leadership
          leaderships={leaderships}
          description={leadershipDescription}
        />
        <section className={styles.images}>
          <div className={styles.wrap}>
            <div className={styles.address}>
              <div className={styles.contactItem}>
                <div className={styles.contactIcon}>
                  <i>
                    <Icon icon="location" size={20} />
                  </i>
                </div>
                <div className={styles.contactText}>
                  <h2>United States</h2>
                  <a href="tel:+12035275119" onClick={()=>gtagWrapper('event', 'click-phone-number', { 'event_category': 'click', })}>+1 (203) 527-5119</a>
                  <a href="mailto:contact@aramaze.com" onClick={()=>gtagWrapper('event', 'click-email', { 'event_category': 'click', })}>contact@aramaze.com</a>
                </div>
              </div>
              <div
                className={styles.image}
                style={{
                  backgroundImage:
                    "url(https://api.aramaze.am/uploads/New_York_3_1_f197309bc1.jpg)",
                }}
              />
            </div>
          </div>
          <div className={styles.wrap}>
            <div className={styles.address}>
              <div className={styles.contactItem}>
                <div className={styles.contactIcon}>
                  <i>
                    <Icon icon="location" size={20} />
                  </i>
                </div>
                <div className={styles.contactText}>
                  <h2>Armenia</h2>
                  <a href="tel:+37496338833" onClick={()=>gtagWrapper('event', 'click-phone-number', { 'event_category': 'click', })}>+374 96 33-88-33</a>
                  <a href="mailto:career@aramaze.com" onClick={()=>gtagWrapper('event', 'click-email', { 'event_category': 'click', })}>career@aramaze.com</a>
                </div>
              </div>
              <div
                className={styles.image}
                style={{
                  backgroundImage:
                    "url(https://api.aramaze.am/uploads/yerevan_5683afcfd6.png)",
                }}
              />
            </div>
          </div>
        </section>
        <ContactUsForm />
      </Body>
    </Layout>
  )
}

export default AboutAsPage
