import React from "react"

import Topic from "./Topic"
import styles from "./Topics.module.scss"

const Topics = ({ topics }) => {
  return (
    <div className={styles.rightCol}>
      {topics.map(topic => (
        <Topic topic={topic} key={topic.id} />
      ))}
    </div>
  )
}

export default Topics
